import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/templates/layoutWithTitle"
import SEO from "../components/seo"

import "../utils/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IndexPage = ({ data, location }) => (
  <Layout title="Nossa Equipe" lang="pt" location={location}>
    <SEO title="Equipe" />

    {data.equipe.nodes.map(member => (
      <>
        <h4>
          {member.name}
          {
            member.linkedin &&
            <a
              href={member.linkedin}
              className="ml-3"
              style={{ fontSize: "0.65em" }}
              target="_blank noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} className="mr-1" />
              LinkedIn
            </a>
          }
        </h4>
        <p className="mb-5">{member.description}</p>
      </>
    ))}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    equipe: allEquipeYaml {
      nodes {
        name
        description
        linkedin
      }
    }
  }
`
